<template>
  <div class="wrapper">
    <section class="upper">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            elevation="5"
            rounded
            color="grey"
            @click="$router.push('/store/orders')"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>

          <!-- <v-btn
         elevation="5"
      rounded
      color="red"
    >
      <v-icon left>
        mdi-cancel
      </v-icon>
      Cancel Order
    </v-btn> -->
          <v-btn elevation="9" rounded color="primary" @click="printOrder">
            <v-icon left>
              mdi-package
            </v-icon>
            Print Order
          </v-btn>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-invoice">
              <div class="card-header text-center">
                <div class="row justify-content-between">
                  <div class="col-lg-4 col-md-5">
                    <div class="row">
                      <div class="col-md-12 display-2">
                        Main Order # {{ $route.params.id }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">Order date:</div>
                      <div class="col-md-6">
                        {{ main_order_date | date_format }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">Purchase Order #:</div>
                      <div v-if="order_detail.length > 0" class="col-md-6">
                        {{ order_detail[0].po_number }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-5 text-left mt-3">
                    <h4 class="mb-1">Billed to:</h4>
                    <p>
                      {{ billto.company_name }}<br />
                      {{ billto.address_line1 }}<br />
                      {{ billto.address_line2 }}<br />
                      {{ billto.city }} {{ billto.state }} {{ billto.zip_code }}
                    </p>
                  </div>

                  <div class="col-lg-3 col-md-5 text-left mt-3">
                    <h4 class="mb-1">Shipped to:</h4>
                    <p>
                      {{ shipto.company_name }}<br />
                      {{ shipto.address_line1 }}<br />
                      {{ shipto.address_line2 }}<br />
                      {{ shipto.city }} {{ shipto.state }} {{ shipto.zip_code }}
                    </p>
                  </div>
                </div>

                <div class="row justify-content-md-between">
                  <div class="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="(orders, index) in order_items"
              :key="index"
              class="mb-4"
            >
              <v-expansion-panel-header
                v-if="orders.length > 0"
                color="#f6f6f6"
              >
                <div class="row justify-content-md-between">
                  <div class="col-md-2">
                    <v-img
                      :src="
                        supplier_logos[orders[0].supplier] != null
                          ? baseurl + supplier_logos[orders[0].supplier]
                          : '/media/bikelogo4.png'
                      "
                      height="75px"
                      width="100px"
                    >
                    </v-img>
                  </div>
                  <div class="col-md-4">
                    <h4 class="mb-1">{{ orders[0].supplier_name }}</h4>
                    <div class="row">
                      <div class="col-md-6">Vendor Order ID:</div>
                      <div class="col-md-3">{{ orders[0].order_id }}</div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-3"></div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <div class="row mt-5">
                      <div class="col-md-12 display-2">
                        Order Total: {{ orders[0].grand_total | dollar_format }}
                      </div>
                      <div class="col-md-12 display-1 ">
                        Status:
                        <span
                          v-if="
                            orders[0].status != '' && orders[0].status != null
                          "
                          style="color:#dc7c43"
                        >
                          {{ orders[0].status }}
                        </span>
                        <span v-else style="color:#dc7c43"> Pending </span>
                      </div>
                    </div>
                  </div>
                </div>

                <template #actions>
                  View Details
                  <v-icon>
                    mdi-arrow-down-bold
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="orders.length > 0">
                <div class="text-lg-right mt-4">
                  <v-btn
                    elevation="9"
                    rounded
                    small
                    class="mr-3"
                    color="grey"
                    @click="reorder(orders)"
                  >
                    <v-icon left>
                      mdi-arrow-left
                    </v-icon>
                    Re-Order
                  </v-btn>

                  <template
                    v-if="
                      orders[0].status != 'cancel' &&
                        orders[0].status != 'cancel requested'
                    "
                  >
                    <template v-if="orders[0].status != 'return requested'">
                      <v-btn
                        elevation="9"
                        rounded
                        small
                        class="mr-3"
                        color="green"
                        @click="
                          $router.push(
                            '/store/order-return/' + orders[0].order_id
                          )
                        "
                      >
                        <v-icon left>
                          mdi-redo-variant
                        </v-icon>
                        Return
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        elevation="9"
                        rounded
                        small
                        class="mr-3"
                        color="green"
                        @click="
                          $router.push(
                            '/store/return-detail/' + orders[0].order_id
                          )
                        "
                      >
                        <v-icon left>
                          mdi-redo-variant
                        </v-icon>
                        Return
                      </v-btn>
                    </template>
                    <v-btn
                      v-if="
                        parseInt(orders[0].qty_invoiced) == 0 &&
                          parseInt(orders[0].qty_shipped) == 0
                      "
                      small
                      elevation="9"
                      rounded
                      color="red"
                      class="mr-3"
                      @click="cancelVendorOrder(orders[0].order_id)"
                    >
                      <v-icon left>
                        mdi-cancel
                      </v-icon>
                      Cancel Order
                    </v-btn>
                    <v-btn
                      small
                      elevation="9"
                      rounded
                      color="primary"
                      class="mr-3"
                      @click="
                        $router.push(
                          '/store/vendor-order-tracking/' + orders[0].order_id
                        )
                      "
                    >
                      <v-icon left>
                        mdi-package
                      </v-icon>
                      View Tracking
                    </v-btn>
                    <v-btn
                      small
                      elevation="9"
                      rounded
                      color="primary"
                      @click="
                        $router.push(
                          '/store/vendor-order-invoice/' + orders[0].order_id
                        )
                      "
                    >
                      <v-icon left>
                        mdi-package
                      </v-icon>
                      View Invoice
                    </v-btn>
                  </template>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table text-right">
                        <thead class="bg-secondary">
                          <tr>
                            <th scope="col" class="text-right text-white">
                              Line #
                            </th>
                            <th scope="col" class="text-right text-white">
                              Item
                            </th>
                            <th scope="col" class="text-right text-white">
                              Qty
                            </th>
                            <th scope="col" class="text-right text-white">
                              Qty Invoiced
                            </th>
                            <th scope="col" class="text-right text-white">
                              Qty Shipped
                            </th>
                            <th scope="col" class="text-right text-white">
                              Qty Returned
                            </th>
                            <th scope="col" class="text-right text-white">
                              Price
                            </th>
                            <th scope="col" class="text-right text-white">
                              Extended Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, idx) in orders" :key="idx">
                            <td>{{ idx + 1 }}</td>
                            <td>
                              {{ item.product_name }}
                              <span v-if="item.item_name != 'default'"
                                >({{ item.item_name }})</span
                              >
                            </td>
                            <td>{{ item.qty }}</td>
                            <td>{{ item.qty_invoiced }}</td>
                            <td>{{ item.qty_shipped }}</td>
                            <td>{{ item.qty_returned }}</td>
                            <td>{{ item.price | dollar_format }}</td>
                            <td>
                              {{ (item.price * item.qty) | dollar_format }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7">Subtotal:</td>
                            <td>{{ orders[0].subtotal | dollar_format }}</td>
                          </tr>
                          <tr>
                            <td colspan="7">Shipping:</td>
                            <td>
                              {{ orders[0].shipping_amount | dollar_format }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="7" class="h4">Total</th>

                            <th colspan="1" class="text-right h4">
                              {{ orders[0].grand_total | dollar_format }}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    panel: 0,
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    supplier_logos: {},
    order_items: {},
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // order_detail: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderDetail($main_order_id: Int) {
    //       order_detail: order_detail(main_order_id: $main_order_id) {
    //         id
    //         supplier
    //         price
    //         qty
    //         qty_invoiced
    //         qty_shipped
    //         qty_returned
    //         item_id
    //         product_id
    //         product_name
    //         item_name
    //         sku_id
    //         supplier_name
    //         url
    //         order_id
    //         status
    //         subtotal
    //         shipping_amount
    //         grand_total
    //         created_at
    //         po_number
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       main_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.$apollo.queries.order_detail.refetch();
    this.getOrderDetail();
  },
  methods: {
    ...mapActions(["getCompanyInfo"]),
    async cancelVendorOrder() {
      // let res = await this.$apollo.mutate({
      //   client: "ecomClient",
      //   mutation: gql`
      //     mutation requestCancelVendorOrderMutation($order_id: Int!) {
      //       requestCancelVendorOrder(order_id: $order_id)
      //     }
      //   `,
      //   variables: {
      //     order_id: order_id,
      //   },
      // });
      // if (res.data.requestCancelVendorOrder == true) {
      //   Swal.fire({
      //     title: "",
      //     text: "Order cancel requested.",
      //     icon: "success",
      //     confirmButtonClass: "btn btn-secondary",
      //   });
      //   this.getOrderDetail();
      // }
    },
    isInt(n) {
      return n % 1 === 0;
    },
    async reorder(orders) {
      let item_arr = [],
        flag = 0;
      for (let i in orders) {
        if (
          !this.isInt(parseFloat(orders[i].qty)) &&
          orders[i].item_id != null
        ) {
          flag = 1;
        }
        item_arr.push(orders[i].item_id + "-" + orders[i].qty);
      }
      if (flag == 1) {
        Swal.fire({
          title: "",
          text: "Invalid qty",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else {
        // let res = await this.$apollo.mutate({
        //   client: "ecomClient",
        //   mutation: gql`
        //     mutation AddCartMutation(
        //       $supplier_id: Int!
        //       $supplier_name: String!
        //       $product: Int!
        //       $item_str: String!
        //     ) {
        //       addCart(
        //         supplier_id: $supplier_id
        //         supplier_name: $supplier_name
        //         product: $product
        //         item_str: $item_str
        //       )
        //     }
        //   `,
        //   variables: {
        //     supplier_id: parseInt(orders[0].supplier),
        //     supplier_name: orders[0].supplier_name,
        //     product: parseInt(orders[0].product_id),
        //     item_str: item_arr.join("_"),
        //   },
        // });
        // if (res.data.addCart == true) {
        //   this.$router.push("/market/home");
        //   Swal.fire({
        //     title: "",
        //     text: "Added to cart.",
        //     icon: "success",
        //     confirmButtonClass: "btn btn-secondary",
        //   });
        // } else {
        //   Swal.fire({
        //     title: "",
        //     text: "Error occured.",
        //     icon: "error",
        //     confirmButtonClass: "btn btn-secondary",
        //   });
        // }
      }
    },
    printOrder() {},
    async getOrderDetail() {
      //get order address
      // let ares = await this.$apollo.query({
      //   client: "ecomClient",
      //   query: gql`
      //     query OrderAddress($main_order_id: Int) {
      //       order_address: order_address(main_order_id: $main_order_id) {
      //         id
      //         main_order_id
      //         address_line1
      //         address_line2
      //         city
      //         state
      //         zip_code
      //         address_type
      //         company_name
      //         first_name
      //         last_name
      //       }
      //     }
      //   `,
      //   variables: {
      //     main_order_id: parseInt(this.$route.params.id),
      //   },
      // });
      // let address_arr = ares.data.order_address;
      // for (let i in address_arr) {
      //   if (address_arr[i].address_type == "billto") {
      //     this.billto = address_arr[i];
      //   } else {
      //     this.shipto = address_arr[i];
      //   }
      // }

      await this.$apollo.queries.order_detail.refetch();
      let order_detail = this.order_detail;
      if (order_detail.length > 0) {
        this.main_order_date = order_detail[0].created_at;
      }

      let t_orders = {};
      this.supplier_logos = {};
      for (let i in order_detail) {
        if (!(order_detail[i].supplier in t_orders)) {
          t_orders[order_detail[i].supplier] = [];

          let res = await this.getCompanyInfo({
            id: order_detail[i].supplier,
          });
          if (res.data.order_logo != null) {
            this.supplier_logos[order_detail[i].supplier] =
              res.data.order_logo.url;
          }
        }
        t_orders[order_detail[i].supplier].push(order_detail[i]);
      }
      this.order_items = t_orders;
    },
  },
};
</script>

<style scoped></style>
